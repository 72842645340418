/* eslint-disable no-underscore-dangle */

/* eslint-disable no-nested-ternary */

/* eslint-disable class-methods-use-this */

/* eslint-disable no-unused-expressions */
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
// import { Link } from 'react-router-dom';
import {
  Button, Col, List, Row, Space, Table, Tag,
} from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import StoreConnector from '../../hoc/StoreConnector';
import {
  getAppointmentListThunk,
  getInvoiceAsZIPThunk,
  getInvoiceListThunk,
  resetAppointmentListThunk,
  resetInvoiceListThunk,
} from '../../redux/thunks/dashboard';
import stateUtils from '../../utils/state';
import './styles.css';

class AppointmentList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      appointmentList: [],
      invoiceList: [],
    };
  }

  componentDidMount() {
    this.reflectProps(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.reflectProps(nextProps);
  }

  componentWillUnmount() {
    this.props.dispatch(resetAppointmentListThunk());
    this.props.dispatch(resetInvoiceListThunk());
  }

  getState(invoice) {
    const tags = [];
    invoice.viewed
      ? invoice.payedAt
        ? tags.push('bezahlt')
        : tags.push('Angeschaut')
      : tags.push('Neu');
    return tags.map((tag) => {
      const color = invoice.viewed
        ? invoice.payedAt
          ? 'green'
          : 'blue'
        : 'red';
      return (
        <Tag color={color} key={tag}>
          {tag.toUpperCase()}
        </Tag>
      );
    });
  }

  getDate(invoice) {
    const invoiceRef = [];
    invoiceRef.push(moment(invoice.date).format('DD.MM.YYYY'));
    return (
      <List
        split={false}
        size="small"
        dataSource={invoiceRef}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />
    );
  }

  async getInvoices(invoiceList) {
    const completedInvoiceList = [];

    invoiceList.map((invoice, i) => {
      const completedInvoice = {
        ...invoice,
        key: i,
        checked: false,
        date: invoice.createdAt,
        viewedAt: invoice.viewedFirstAt,
        price: `CHF ${invoice.amount}`,
        therapistName: `${invoice.CentreProvider.User.firstName} ${invoice.CentreProvider.User.lastName}`,
        invoiceNumber: invoice.number,
      };

      return completedInvoiceList.push(completedInvoice);
    });
    console.log('completedInvoiceList', completedInvoiceList);

    this.setState({ invoiceList: completedInvoiceList }, () => this.setState({ invoiceList: completedInvoiceList }));
  }

  async downloadPDFs() {
    const invoicesToZip = this.state.selectedRows.map(
      (listEntry) => listEntry.invoice_id,
    );

    this.props.dispatch(getInvoiceAsZIPThunk(invoicesToZip));
  }

  async downloadPDF(invoice) {
    this.props.dispatch(getInvoiceAsZIPThunk([invoice.invoice_id]));
  }

  reflectProps(props) {
    if (
      !props.isFetchingAppointmentList
      && !props.isValidAppointmentList
      && !props.isFailedFetchingAppointmentList
    ) {
      props.dispatch(getAppointmentListThunk(props.user.user_id));
    }
    if (props.isValidAppointmentList) {
      this.setState({ appointmentList: this.props.appointmentList }, () => this.setState({ appointmentList: this.props.appointmentList }));
    }
    if (
      !props.isFetchingInvoiceList
      && !props.isValidInvoiceList
      && !props.isFailedFetchingInvoiceList
    ) {
      props.dispatch(getInvoiceListThunk(props.user.user_id));
    }
    if (props.isValidInvoiceList) {
      this.getInvoices(this.props.invoiceList);
      this.setState({ invoiceList: this.props.invoiceList }, () => this.getInvoices(this.props.invoiceList));
    }
  }

  render() {
    console.log('props, state', this.props, this.state);

    const columns = [
      {
        title: 'Datum',
        render: (invoice) => this.getDate(invoice),
        key: 'date',
        defaultSortOrder: 'descend',
        sorter: (a, b) => moment(a.createdAt).diff(b.createdAt),
      },
      {
        title: 'Re-Nr',
        dataIndex: 'invoiceNumber',
        responsive: ['md'],
        key: 'invoiceNumber',
      },
      {
        title: 'Therapeut',
        dataIndex: 'therapistName',
        responsive: ['lg'],
        key: 'therapistName',
      },
      {
        title: 'Preis',
        dataIndex: 'price',
        responsive: ['sm'],
        key: 'price',
      },
      {
        title: 'Status',
        responsive: ['sm'],
        key: 'status',
        sortDirections: ['descend'],
        render: (invoice) => this.getState(invoice),
      },
      {
        title: 'Bezahlt am',
        dataIndex: 'payedAt',
        responsive: ['sm'],
        render: (date) => (date ? moment(date).format('DD.MM.YYYY') : '-'),
        key: 'payedAt',
      },
      {
        title: (
          <Row justify="end">
            <Button
              type="ghost"
              disabled={_.isEmpty(this.state.selectedRows)}
              onClick={() => this.downloadPDFs()}
              icon={<DownloadOutlined />}
            />
          </Row>
        ),
        key: 'action',
        render: (text, record) => (
          <Row justify="end">
            <Space
              size="middle"
              direction={window.innerWidth < 400 ? 'vertical' : 'horizontal'}
            >
              <Link to={`/dashboard/invoice/${record.invoice_id}`}>
                <Button type="ghost" icon={<EyeOutlined />} />
              </Link>
              <Button
                type="ghost"
                onClick={() => this.downloadPDF(record)}
                icon={<DownloadOutlined />}
              />
            </Space>
          </Row>
        ),
      },
    ];

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRows });
      },
      onSelectAll: (selected, selectedRows) => {
        this.setState({ selectedRows });
      },
    };

    return (
      <div className="appointmentList">
        <Row align="top" justify="space-between" style={{ height: '100%' }}>
          <Col span={24}>
            <Table
              columns={columns}
              rowSelection={{ ...rowSelection }}
              showSorterTooltip={false}
              dataSource={this.state.invoiceList}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default StoreConnector(AppointmentList, stateUtils.fullStateMap, {});
